var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"avatar-group"},[_vm._l((_vm.otherProfiles),function(profile,index){return _c('span',{key:index,staticClass:"avatar",style:({
      'z-index': _vm.profiles.length - index,
      'width': _vm.size,
      'height': _vm.size,
      'border': _vm.border
    })},[(_vm.hasPhoto(profile))?_c('img',{attrs:{"src":profile.photoURL}}):_c('img',{attrs:{"src":"/static/img/user.jpg"}})])}),_c('span',{staticClass:"avatar",style:({
      'width': _vm.size,
      'height': _vm.size,
      'border': _vm.border
    })},[(_vm.hasPhoto(_vm.userProfile))?_c('img',{attrs:{"src":_vm.userProfile.photoURL}}):_c('img',{attrs:{"src":"/static/img/user.jpg"}})]),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }