<template>
  <mdc-list class="duomenu">

    <mdc-list-item class="side-menu-item">
      <router-link v-on:click.native="toggleSidebar" to="/duos">
        <div class="side-menu-heading sublist1">
          <span class="mdc-list-item__graphic">
            <mdc-icon slot="start-detail" class="material-icons--outlined" icon="supervised_user_circle"></mdc-icon>
          </span>
          <mdc-text typo='body2' tag="div">duos</mdc-text>
        </div>
      </router-link>
      <mdc-list  v-if="profile" interactive>

        <mdc-list-item v-for="duo in duos" :key="duo.id"  class="duo">
          <router-link v-on:click.native="toggleSidebar" :to="'/duo-proposal/' + duo.connection">
            <div class="side-menu-item connectionrow sublist2">
              <avatar-group border="#218ad3" size="22px" v-if="duo.duoProfiles" :userProfile="profile" :profiles="duo.duoProfiles">
                <mdc-body typo="body2" style="padding-left:24px;white-space:nowrap;">
                  <span>{{otherUser(duo.users).displayName}} . </span>
                  <span v-if="isActive(duo)">Active</span>
                  <span v-else>Draft</span>
                </mdc-body>
              </avatar-group>
            </div>
          </router-link>
        </mdc-list-item>

      </mdc-list>
    </mdc-list-item>

  </mdc-list>
</template>

<script>
import { mapState } from 'vuex'
import AvatarGroup from '../components/AvatarGroup.vue'
export default {
  name: 'duoMenu',
  data () {
    return {
    }
  },
  components: {
    AvatarGroup
  },
  computed: {
    ...mapState([
      'user',
      'profile',
      'profiles'
    ]),
    ...mapState({
      duos: (state) => { return state.duo.duos }
    })
  },
  methods: {
    duoProfiles: function (duo) {
      const duoProfiles = []
      if (duo) {
        if (duo.users) {
          for (const prof of Object.keys(duo.users)) {
            duoProfiles.push(this.getProfile(prof))
          }
        }
      }
      return duoProfiles
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      if (profileId === 'bot') {
        return {
          displayName: 'DuoMe Bot',
          photoURL: '/static/img/chat-bubble-circle.svg'
        }
      }
      const profileMap = new Map(this.profiles.map(i => [i.id, i]))
      const profile = profileMap.get(profileId)
      if (profile) {
        return profile
      }
      return {}
    },
    isActive (duo) {
      var active = false
      if (duo) {
        var allAgree = true
        if (duo.agreement) {
          Object.values(duo.agreement).forEach((agree) => {
            allAgree = allAgree && agree
          })
          active = allAgree
        }
      }
      return active
    },
    otherUser: function (users) {
      const usersCopy = Object.assign({}, users)
      delete usersCopy[this.profile.id]
      if (Object.keys(usersCopy).length === 1) {
        const profileId = Object.keys(usersCopy)[0]
        const profileMap = new Map(this.profiles.map(i => [i.id, i]))
        return profileMap.get(profileId)
      }
      return {}
    },
    toggleSidebar: function () {
      this.$root.$emit('toggleSidebar')
    }
  },
  watch: {
    duos: function (newd, oldd) {
      if (this.duos) {
        for (const duo of this.duos) {
          duo.duoProfiles = this.duoProfiles(duo)
        }
      }
    }
  },
  mounted () {
    if (this.duos) {
      for (const duo of this.duos) {
        duo.duoProfiles = this.duoProfiles(duo)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.duomenu .avatar {
  width:20px;
  height: 20px;
  margin-right: 6px;
}
.duomenu .duosrow, .duosrow > div {
  display:flex;
  font-size:12px;
  flex-flow: row nowrap;
  white-space: nowrap;
  margin-right:6px;
  height: 24px;
  line-height: 24px;
  overflow: hidden;
}
.duomenu a {
  color: #333333;
  text-decoration: none;
}
.duomenu .mdc-list-item {
  height:24px;
  margin: 0 6px 0 0;
}
.duomenu .side-menu-item > a {
  margin-bottom: 5px;
}
</style>
