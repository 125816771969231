<template>
  <span class="avatar-group">
    <span
      v-for="(profile, index) in otherProfiles"
      :key="index" class="avatar"
      :style="{
        'z-index': profiles.length - index,
        'width': size,
        'height': size,
        'border': border
      }"
      >
      <img v-if="hasPhoto(profile)" :src="profile.photoURL">
      <img v-else src="/static/img/user.jpg">
    </span>
    <span class="avatar"
      :style="{
        'width': size,
        'height': size,
        'border': border
      }">
      <img v-if="hasPhoto(userProfile)" :src="userProfile.photoURL">
      <img v-else src="/static/img/user.jpg">
    </span>
    <slot/>
  </span>
</template>

<script>
export default {
  name: 'AvatarGroup',
  props: {
    profiles: {
      type: Array
    },
    userProfile: {
      type: Object
    },
    size: {
      default: '56px'
    },
    border: {
      default: '1px solid white'
    }
  },
  computed: {
    otherProfiles () {
      return this.profiles.filter(profile => profile.id !== this.userProfile.id)
    }
  },
  method: {
    hasPhoto (profile) {
      if (profile.photoURL) {
        return true
      }
      return false
    }
  },
  mounted () {
  }
}
</script>
<style scoped>
.avatar-group {
  flex:0;
  display:flex;
  flex-flow:row;
  align-items:center;
  position: relative;
}
.avatar-group .avatar {
    width:56px;
    height: 56px;
    border-radius: 100%;
    display: block;
    overflow: hidden;
    margin-left:-6px;
    margin-right:-6px;
}
.avatar-group .avatar img {
    height: 100%;
    width: 100%;
    border-radius: 100%;
    object-fit: cover;
}
</style>
