<template>
  <div class="main-content">
    <div class="main-wrapper duo" style="margin-top: 56px;">

      <mdc-layout-grid class="main-content-inner">
        <mdc-layout-cell span=12>

          <mdc-card class="page-card">
            <mdc-card-primary-action>
              <mdc-card-media class="image-cover" src="/static/img/duo.png">
                <mdc-card-header>
                  <mdc-card-action-icon style="color:white" @click="$router.go(-1)" icon="arrow_back_ios" />
                  <mdc-card-title>Duo Proposal</mdc-card-title>
                  <mdc-card-subtitle>Sharing and Controls</mdc-card-subtitle>
                </mdc-card-header>
              </mdc-card-media>
            </mdc-card-primary-action>

            <mdc-card-actions>
              <mdc-card-action-icons>
              </mdc-card-action-icons>
            </mdc-card-actions>

            <mdc-card-text style="margin-top:-80px">
              <div v-if="duo">
              <mdc-list class="info-section">
                <mdc-list-item class="info-item" style="padding-top:50px;">
                  <div class="info-title">
                    <avatar-group :userProfile="profile" :profiles="duoProfiles">
                      <mdc-title style="padding-left:12px;white-space:nowrap;">
                        <span style="padding-right:6px;">Duo Status is</span>
                        <span v-if="isActive()" style="color:#AD1457;font-weight:500">Active</span>
                        <span v-else style="color:#AD1457;font-weight:500">Not Active</span>
                      </mdc-title>
                    </avatar-group>
                  </div>
                  <div class="info-title center">
                    <div class="action-text hover-info">
                      <span>Your proposal has been viewed {{duo.viewed}} times</span>
                    </div>
                    <mdc-title>
                      Activity
                      <mdc-icon icon="show_chart"></mdc-icon>
                    </mdc-title>
                  </div>
                  <div class="info-title right">
                    <div class="action-text hover-info">
                      <mdc-textfield id="url" style="width:390px;" dense disabled v-model="url"/>
                      <mdc-button @click="copyLink">Copy</mdc-button>
                    </div>
                    <mdc-title class="sharing-indicator">
                      Public Sharing is
                      <span v-if="duo.public" style="color:#AD1457;font-weight:500;padding-left:6px"> ON</span>
                      <span v-else style="color:#AD1457;font-weight:500;padding-left:6px"> OFF</span>
                      <mdc-icon v-if="duo.public" icon="link"></mdc-icon>
                      <mdc-icon v-else icon="link_off"></mdc-icon>

                    </mdc-title>
                  </div>
                </mdc-list-item>
              </mdc-list>

              <mdc-list class="info-section">
                <mdc-list-item class="info-item">
                  <mdc-title class="info-section-title">Control</mdc-title>

                  <mdc-title class="info-header">Status</mdc-title>
                  <div class="info-text">
                  <mdc-body  >Set your proposal to active to enable sharing
                    <i @click="tooltipStatus = !tooltipStatus" class="material-icons tooltip-icon">help_outline</i>
                  </mdc-body>
                  <mdc-body v-if="tooltipStatus" typo="body2">
                    Both members of the Duo need to set the proposal to active to enable sharing.
                    If you choose that you no longer wish to share this proposal either person can set it to not active at any time.
                  </mdc-body>
                  </div>

                  <div class="agreement-section">
                    <span class="agreement" v-for="(user, index) in duo.users" :key="index">
                      <div class="avatar"><img :src="getProfile(index).photoURL"/></div>
                      <mdc-body typo="body2" class="agreement-text">
                        <div>
                          <span v-if="index === profile.id" >You are </span>
                          <span v-else>{{getProfile(index).displayName}} is </span>
                          <span v-if="!duo.agreement[index]"><b>Not Active</b></span>
                          <span v-else><b>Active</b></span>
                        </div>
                        <div>
                          <span v-if="!duo.agreement[index]"><i>Not Agreed</i></span>
                          <span v-else><i>Agreed {{duo.agreementDates[index]}}</i></span>
                        </div>
                      </mdc-body>
                      <div v-if="index === profile.id" class="agreement-switch">
                        <mdc-switch :value="now()" :disabled="index !== profile.id" @change="updateAgreement" v-model="duo.agreement[index]" style="z-index:9;">
                        </mdc-switch>
                      </div>
                    </span>
                  </div>
                </mdc-list-item>
              </mdc-list>

              <mdc-list class="info-section">
                <mdc-list-item class="info-item">
                  <mdc-title class="info-section-title">Edit</mdc-title>
                  <mdc-title class="info-header">View</mdc-title>
                  <mdc-body class="info-text">View your profile as it will appear to those you share it with.</mdc-body>
                  <a style="text-decoration: none;" :href="`/duo/${duo.id}`" target="_blank">
                  <mdc-button style="display:flex" raised >
                    <mdc-icon style="height: 24px;margin-right: 12px;" icon="pageview"></mdc-icon>
                    View proposal</mdc-button>
                  </a>
                </mdc-list-item>

                <mdc-list-item class="info-item">
                  <mdc-title class="info-header">Edit</mdc-title>
                  <div class="info-text">
                    <mdc-body>Edit your master proposal cover letter here.
                      <i @click="tooltipEdit = !tooltipEdit" class="material-icons tooltip-icon">help_outline</i>
                    </mdc-body>
                    <mdc-body v-if="tooltipEdit" typo="body2">If you choose to share your proposal publically, this is the version that will be shared so keep it general vs being specific for a particular job.  Specific proposal can be added with custom covering letters below.</mdc-body>
                  </div>
                  <mdc-button style="display:flex" @click="$router.push('/share-proposal/' + duo.connection)" outlined>
                    <mdc-icon style="height: 24px;margin-right: 12px;" icon="create"></mdc-icon>
                    Edit proposal</mdc-button>
                </mdc-list-item>
              </mdc-list>

              <mdc-list class="info-section" :class="{disabled: !isActive()}">
                <mdc-list-item class="info-item">

                  <mdc-title v-if="isActive()" class="info-section-title">Share</mdc-title>
                  <mdc-title v-else title="Duo must be active to enabled sharing" class="info-section-title">Share</mdc-title>

                  <mdc-title class="info-header">Public</mdc-title>
                  <div class="info-text">
                    <mdc-body >Turn on to create a public proposal page which is visible to anyone with the link.
                      <i @click="tooltipPublic = !tooltipPublic" class="material-icons tooltip-icon">help_outline</i>
                    </mdc-body>
                    <mdc-body v-if="tooltipPublic" typo="body2">This makes it super simple to share but does mean its possible for anyone to view it if they have your link. You can always turn it off if it gets into the wrong hands.</mdc-body>
                  </div>
                  <mdc-button :disabled="true" title="Duo must be active to enabled sharing" v-if="!duo.public && !isActive()" outlined>
                    <mdc-icon icon="link"></mdc-icon>
                    Turn on public link</mdc-button>
                  <mdc-button v-else-if="!duo.public && isActive()" @click.prevent="makePublic" outlined>
                    <mdc-icon icon="link"></mdc-icon>
                    Turn on public link</mdc-button>
                  <mdc-button v-else outlined @click.prevent="makePrivate" >
                    <mdc-icon icon="link_off"></mdc-icon>
                    Turn off public link</mdc-button>
                </mdc-list-item>

                <mdc-list-item class="info-item">
                  <mdc-title class="info-header">Email</mdc-title>
                  <div class="info-text">
                    <mdc-body>Control the visibility of your proposal by sharing it with specific people
                      <i @click="tooltipEmail = !tooltipEmail" class="material-icons tooltip-icon">help_outline</i>
                    </mdc-body>
                    <mdc-body v-if="tooltipEmail" typo="body2">Using email lets you see if the person accesses the proposal and gives better control to restrict who can view.</mdc-body>
                  </div>
                  <mdc-button v-if="!isActive()" :disabled="true" title="Duo must be active to enabled sharing" outlined>
                    <mdc-icon icon="mail_outline"></mdc-icon>
                    Email access</mdc-button>
                  <mdc-button v-else  @click="shareEmail = true" outlined>
                    <mdc-icon icon="mail_outline"></mdc-icon>
                    Email access</mdc-button>
                </mdc-list-item>
              </mdc-list>

              <mdc-list class="info-section" style="border-bottom:none;">
                <mdc-list-item class="info-item">
                  <mdc-title class="info-section-title">Customize</mdc-title>
                  <mdc-title class="info-header">Add</mdc-title>
                  <div class="info-text">
                    <mdc-body>Customise the cover letter of your proposal so that it can be specific for a particular role or position.
                      <i @click="tooltipCover = !tooltipCover" class="material-icons tooltip-icon">help_outline</i>
                    </mdc-body>
                    <mdc-body v-if="tooltipCover" typo="body2">Add a new version, click the link to edit, then chosse who you’d like to send it to.  Customised covers can’t be made public.</mdc-body>
                  </div>
                  <mdc-button @click="addCover" outlined>
                    <mdc-icon icon="add"></mdc-icon>
                    Add custom cover</mdc-button>
                </mdc-list-item>

                <mdc-list-item class="info-item cover-section">
                  <span class="info-header"></span>
                  <div class="info-text" style="flex:1;">
                    <mdc-list v-if="covers.length > 0">
                      <mdc-list-item class="cover-item" v-for="(cover, index) in covers" :key="index">
                        <span style="flex-grow:0.5;">Cover {{index+1}}</span>
                        <span>{{cover.title}}</span>
                        <span>
                          <mdc-button title="view" style="display:flex" :to="`/duo/${duo.id}/${index}`" >
                            <mdc-icon style="height: 24px;margin-right: 12px;" icon="pageview"></mdc-icon>
                          </mdc-button>
                          <mdc-button title="edit" style="display:flex" @click="editCover(index)">
                            <mdc-icon style="height: 24px;margin-right: 12px;" icon="create"></mdc-icon>
                          </mdc-button>
                          <mdc-button v-if="isActive()" title="email" style="display:flex" @click="openSendCover(index)">
                            <mdc-icon style="height: 24px;margin-right: 12px;" icon="mail_outline"></mdc-icon>
                          </mdc-button>
                          <mdc-button v-else :disabled="true" title="Duo must be active to enabled sharing" style="display:flex">
                            <mdc-icon style="height: 24px;margin-right: 12px;" icon="mail_outline"></mdc-icon>
                          </mdc-button>
                          <span class="info-title">
                            <div class="action-text hover-info">Your proposal has been viewed {{cover.viewed || 0}} times</div>

                            <mdc-button title="activity" style="display:flex" >
                              <mdc-icon style="height: 24px;margin-right: 12px;" icon="show_chart"></mdc-icon>
                            </mdc-button>
                          </span>
                          <mdc-button title="delete" style="display:flex" @click="removeCover" >
                            <mdc-icon style="height: 24px;margin-right: 12px;" icon="delete_outline"></mdc-icon>
                          </mdc-button>
                        </span>
                      </mdc-list-item>
                    </mdc-list>
                    <div v-else class="cover-item"> No custom covers set </div>

                  </div>
                </mdc-list-item>
                <span class="spacer" style="width:250px;"></span>

                <mdc-list-item>
                  <div class="spacer"></div>
                </mdc-list-item>

              </mdc-list>
              </div>
              <div v-else>
              </div>

              <mdc-dialog v-if="duo" v-model="shareEmail"
              title="Share via Email" accept="Save" cancel="Cancel"
              @accept="onShareAccept" @cancel="onShareCancel" @close="onShareCancel"
              >
                <mdc-body typo="body2">
                  Share with others, add the person who you’d like to share withs email
                  address and we will forward them a link to give them access
                </mdc-body>
                <div style="margin:0;margin-bottom:12px;" class="action-text">
                  <mdc-textfield type="email" id="url" style="width:100%" label="Add Email address here" v-model="newEmail"/>
                  <mdc-button @click="addShareEmail">Share</mdc-button>
                </div>
                <div>
                  <mdc-body typo="body2">
                    Currently shared with:
                    <span v-for="(share, index) in shareEmails" :key="index">{{share}} <span @click="removeEmail(index)" ><mdc-icon class="email-clear" icon="clear"/></span>, </span>
                  </mdc-body>
                </div>
              </mdc-dialog>

              <mdc-dialog v-if="duo" v-model="editCoverOpen"
              title="Edit Custom Cover" accept="Save" cancel="Cancel"
              @accept="onCoverAccept" @cancel="onCoverCancel"
              >
                <mdc-textfield style="width:100%" label="Add Cover Title here" v-model="cover.title"/>
                <tiptap-editor style="width:100%" @onTextUpdate="onTextUpdate" :title="'Cover Text'" :content="duoCoverText" :contentKey="'shareProposal'"> </tiptap-editor>

              </mdc-dialog>

              <mdc-dialog v-if="duo" v-model="sendCoverOpen"
              title="Send Custom Cover" accept="Ok"
              >
                <div style="margin:0;margin-bottom:12px;" class="action-text">
                  <mdc-textfield type="email" id="url" style="width:100%"  label="Add Email address here" v-model="newCoverEmail"/>
                  <mdc-button @click="sendCoverEmail">Send</mdc-button>
                </div>

              </mdc-dialog>

            </mdc-card-text>
          </mdc-card>

        </mdc-layout-cell>
      </mdc-layout-grid>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AvatarGroup from '../components/AvatarGroup.vue'
import DuoMenu from '../components/DuoMenu.vue'
import { now } from '../utils/time'
import { mail } from '../utils/backend'
export default {
  name: 'duo',
  components: {
    TiptapEditor: () => import('../components/Tiptap'),
    AvatarGroup
  },
  data () {
    return {
      title: 'Duo',
      links: [
        { component: DuoMenu }
      ],
      shareEmail: false,
      connectionId: null,
      connection: null,
      newEmail: '',
      newCoverEmail: '',
      shareEmails: [],
      coverText: '',
      duoCoverText: '',
      covers: [],
      coverId: null,
      cover: {},
      editCoverOpen: false,
      sendCoverOpen: false,
      tooltipEmail: false,
      tooltipPublic: false,
      tooltipEdit: false,
      tooltipCover: false,
      tooltipStatus: false,
      duoProfiles: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      profile: state => state.profile,
      profiles: state => state.profiles,
      duo: state => state.duo.duo
    }),
    url () {
      return `https://duome.co/duo/${this.duo.id}`
    }
  },
  methods: {
    ...mapActions(
      [
        'getDuoByConnection',
        'setDuoConnection',
        'setSnackMessage',
        'setLoading',
        'setPageLoad'
      ]
    ),
    isActive () {
      var active = false
      if (this.duo) {
        var allAgree = true
        if (this.duo.agreement) {
          Object.values(this.duo.agreement).forEach((agree) => {
            allAgree = allAgree && agree
          })
          active = allAgree
        }
      }
      return active
    },
    getProfile (profileId) {
      if (profileId === this.profile.id) {
        return this.profile
      }
      if (profileId === 'bot') {
        return {
          displayName: 'DuoMe Bot',
          photoURL: '/static/img/chat-bubble-circle.svg'
        }
      }
      const profileMap = new Map(this.profiles.map(i => [i.id, i]))
      const profile = profileMap.get(profileId)
      if (profile) {
        return profile
      }
      return {}
    },
    now () {
      return now()
    },
    copyLink () {
      const el = document.createElement('input')
      el.value = this.url
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    validEmail (email) {
      if (email === '') {
        return false
      }
      return true
    },
    addShareEmail () {
      if (!this.shareEmails) {
        this.shareEmails = []
      }
      if (this.validEmail(this.newEmail)) {
        this.shareEmails.push(this.newEmail)
        const mailMessage = {
          to: this.newEmail,
          duo: this.duo.id
        }
        mail('send-duo-viewing-invite', mailMessage)
        this.newEmail = ''
      }
    },
    openSendCover (coverId) {
      this.coverId = coverId
      this.sendCoverOpen = true
    },
    sendCoverEmail (coverId) {
      if (this.validEmail(this.newCoverEmail)) {
        const mailMessage = {
          to: this.newCoverEmail,
          duo: this.duo.id,
          cover: this.coverId
        }
        mail('send-duo-viewing-invite', mailMessage)
        this.newCoverEmail = ''
      }
    },
    updateAgreement (val) {
      if (!val) {
        this.duo.public = false
      } else {
        this.duo.agreementDates[this.profile.id] = this.now()
      }
      this.saveDuo()
    },
    onShareAccept () {
      this.saveDuo()
    },
    onShareCancel () {
      this.shareEmails = this.duo.shareEmails
      this.shareEmail = false
    },
    removeEmail (idx) {
      this.shareEmails.splice(idx, 1)
      this.saveDuo()
    },
    addCover () {
      if (!this.covers) {
        this.covers = []
      }
      const newCover = { title: 'title', text: '', viewed: 0 }
      this.covers.push(newCover)
      this.saveDuo()
    },
    editCover (idx) {
      this.cover = this.covers[idx]
      this.coverText = this.cover.text
      this.duoCoverText = this.cover.text
      this.editCoverOpen = true
    },
    onTextUpdate: function (text, contentKey) {
      this.coverText = text
    },
    removeCover (idx) {
      this.covers.splice(idx, 1)
      this.saveDuo()
    },
    onCoverAccept () {
      this.cover.text = this.coverText
      this.coverText = ''
      this.saveDuo()
    },
    onCoverCancel () {
      const self = this
      this.covers = this.duo.covers
      this.duoCoverText = this.coverText
      this.coverText = ''
      this.$nextTick(() => {
        self.duoCoverText = self.cover.text
      })
    },
    makePublic () {
      this.duo.public = true
      this.saveDuo()
    },
    makePrivate () {
      this.duo.public = false
      this.saveDuo()
    },
    saveDuo () {
      this.duo.covers = this.covers
      this.duo.shareEmails = this.shareEmails
      this.setDuoConnection({ duoId: this.duo.id, duo: this.duo })
    },
    initialize () {
      if (this.duo) {
        if (this.duo.shareEmails) {
          this.shareEmails = this.duo.shareEmails
        }
        if (this.duo.covers) {
          this.covers = this.duo.covers
        }
        this.duoProfiles = []
        if (this.duo.users) {
          for (const prof of Object.keys(this.duo.users)) {
            this.duoProfiles.push(this.getProfile(prof))
          }
        }
      }
    }
  },
  beforeDestroy () {
    this.$emit('setLinks', [])
    this.$emit('setNavActive', null)
    this.$root.$emit('enableSearch', false)
  },
  async beforeRouteUpdate (to, from, next) {
    this.setLoading(true)
    this.connection = null
    this.connectionId = to.params.id
    if (this.user.uid) {
      this.getDuoByConnection({ connectionId: this.connectionId, userId: this.user.uid })
    }
    this.setLoading(false)
    setTimeout(() => {
      console.log('Duo.beforeRouteUpdate.enabledSearch')
      this.$root.$emit('enableSearch', true, { back: true, context: 'duos' })
    }, 300)
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
    next()
  },
  mounted () {
    this.$emit('setLinks', this.links)
    this.$emit('setNavActive', 'Duos')
    this.$root.$emit('enableSearch', true, { back: true, context: 'duos' })
    this.connectionId = (this.$route) ? this.$route.params.id : '1234'
    if (this.user.uid) {
      this.getDuoByConnection({ connectionId: this.connectionId, userId: this.user.uid })
    }
    setTimeout(() => {
      this.setPageLoad(false)
    }, 600)
  },
  watch: {
    duo: function (newD, oldD) {
      this.initialize()
    },
    user: function (newU, oldU) {
      if (newU.uid) {
        this.getDuoByConnection({ connectionId: this.connectionId, userId: this.user.uid })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.duo .cover-item {
  background-color:#F2F2F2;
  padding:6px;
  flex:1;
  margin-bottom:6px;
  flex-wrap:wrap;
}
.duo .cover-item .info-title .action-text.hover-info{
  margin-top:-80px;
  z-index:999;
}
.duo .sharing-indicator {
  white-space: nowrap;
  align-items: center;
}
.duo .agreement-section {
  display:flex;
  flex-flow:column;
}
.duo .agreement-section > span {
  display:flex;
  flex-flow:row;
  flex-wrap:wrap;
  padding:4px;
  margin-bottom:12px;
  border-radius:5px;
  align-items: center;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.duo .agreement-section > span > div {
  padding:6px;
}
.duo .agreement-section .agreement-text {
  display:flex;
  flex-wrap:wrap;
  flex-flow:column;
  padding-left: 8px;
  padding-right: 8px;
  min-width:120px;
}
.duo .agreement-section .agreement-switch {
  width:50px;
  flex:0;
}
.duo .agreement-section .agreement-switch .mdc-switch-label * {
  white-space: nowrap;
}
.duo .name-group {
  margin-left:18px;
  flex-flow:column;
}
.duo .name-group * {
  padding:6px;
  margin:0;
}
.duo .avatar {
    width:56px;
    height: 56px;
    border-radius: 100%;
    display: block;
    overflow: hidden;
    margin-left:-6px;
    margin-right:-6px;
}
.duo .avatar img {
    height: 100%;
    width: 100%;
    border-radius: 100%;
    object-fit: cover;
}
.duo .action-text {
  display:flex;
  align-items: center;
  background-color:white;
  border-radius:5px;
  margin-top:12px;
  height:46px;
  margin-left:16px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  padding:12px;
}
.duo .info-title {
  position:relative;
  flex-wrap:wrap;
}
.duo .info-text p{
  margin-top:20px;
}
.duo .info-item {
  height:auto;
  flex-wrap:wrap;
}
.duo .info-item .mdc-button {
  margin-top:20px;
}
.info-section {
  padding:12px;
  margin:12px;
  border-bottom:2px solid #C9C9C9;
}
.info-section-title {
  text-decoration: underline;
  margin: 0;
  display: block;
  width: 100%;
}
.duo .info-item .info-title {
  flex:1;
  height: 72px;
  align-items: center;
}
.duo .info-item .info-title *{
  display:flex;
}
.duo .info-item .info-header{
  width: 100px;
  display: block;
  flex-shrink: 0;
  margin-top: 16px;
}
.duo .info-item .mdc-button{
  display: block;
  flex-shrink: 0;
  width: 240px;
}
.duo .info-title .action-text.hover-info {
    position: absolute;
    display: none;
    margin-top: -50px;
    right: 0;
}
.duo .info-title:hover .action-text.hover-info {
  display:flex;
}
.duo .info-title .action-text.hover-info .mdc-textfield {
  width: auto;
  margin-top: 6px;
  flex: 1;
}
.duo .info-title .action-text.hover-info .mdc-textfield .mdc-text-field__input {
  padding-top: 0;
}
.duo .info-title .action-text.hover-info .mdc-button {
  flex: 0;
  width:auto;
  margin-top: 0;
}
.duo .cover-item {
  height:auto;
  overflow: visible;
}
.duo .cover-item .info-title {
  height:auto;
  overflow: visible;
  width:auto;
  min-width:64px;
  flex:0;
  position: static;
}
.duo .cover-item .info-title:hover .action-text.hover-info {
  top: 50px;
}
.duo .mdc-list-item.cover-item > span {
  display:flex;
  flex:1;
}
.duo .mdc-list-item.cover-item  .mdc-button {
  width:auto;
}
.duo .email-clear {
  font-size: 16px;
  position: relative;
  top: 2px;
  cursor:pointer;
  padding-left:6px;
  padding-right:6px;
}
.duo .spacer {
  height:20px;
}
.duo .cover-item .mdc-button {
  margin-top:6px;
}
.duo .info-section .info-text .mdc-typography--body2{
  color:#929292;
}
.duo .info-section.disabled p, .duo .info-section.disabled h3 {
  color:#c3c3c3;
}
.duo .info-section.disabled .info-text .mdc-typography--body2{
  color:#c3c3c3;
}
.duo .info-title.center {
  display: flex;
  justify-content: center;
}
.duo .info-title.right {
  display: flex;
  justify-content: flex-end;
}
.agreement-section .agreement {
  width:240px;
}
.agreement-section .agreement .avatar {
  width:42px;
  height:42px;
}
.duo .mdc-card__supporting-text,
.duo .mdc-card__supporting-text > .mdc-list {
  padding: 0;
}
.duo .cover-section {
  padding-top:40px;
  overflow: visible;
}
.duo .material-icons.tooltip-icon {
  font-size: 16px;
  position:relative;
  top: 4px;
  cursor: pointer;
}
.duos .duo {
    height: auto;
}
.duos .duo .mdc-button {
  min-width:32px;
  height:42px;
}
.duos .duo .duo-row {
  display:flex;
  flex:1;
  align-items:center;
}
.duos .duo .duo-status {
  flex:2;
  padding-left:12px;
  padding-right:12px;
  white-space:nowrap;
}
.duos .duo:hover {
  background-color: #F2F2F2;
}
.duos .duo .mdc-layout-grid {
  width:100%;
  padding:12px;
}
.duos .mdc-card__supporting-text,
.duos .mdc-card__supporting-text > .mdc-list {
  padding: 0;
}
@media(min-width: 871px) {
  .duo .info-text {
    padding-left:24px;
    padding-right:24px;
    flex:1;
  }
  .duo .info-item .info-title span {
    white-space: nowrap;
  }
}
@media(max-width: 840px) {
  .duo .info-item {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
  .duo .info-text {
    width: 100%;
  }
  .duo .info-title {
    width: 100%;
    justify-content: flex-start !important;
  }
  .duo .info-title h3 {
    margin: 0;
  }
  .duo .info-title:not(:first-of-type) h3 {
    margin: 0 0 4px 79px;
  }
  .duo .mdc-list-item.cover-item > span {
    width: 100%;
  }
  .duo .mdc-list-item.cover-item .mdc-button {
    min-width: 20%;
    margin: 0 auto;
  }
  .duo .info-item .info-header {
    margin-bottom: 0;
    width: 100%;
    font-size: 1rem;
  }
  .duo .info-item .info-title h3 {
      font-size: 1rem;
  }
  .duo .info-title .action-text.hover-info {
    position: absolute;
    display: none;
    left: 16px;
    right: 16px;
    margin: 0;
    top: -40px;
    z-index: 999;
    width: 80%;
  }
}
</style>
